const TankProtectionSolutions = () => {
  return (
    <div>
      <p>The Enardo Series 850 Pipe-away and 950 Vent-to-atmosphere provide pressure & vacuum relief in applications that require
	  hazardous vapors while providing safety and emission control.</p>
      <h1>Features:</h1>
      <ul class="ul1">
	  <li>Model 850 - pipe away</li>
	  <li> Model 950 - vent to atmosphere</li>
	  <li> Sizes 2 through 12 available for different tank sizes</li>
	  <li> Leakage control: Designed to have leakage not exceeding 0.1 SCH (Standard Cubic Feet per Hour) at 90% of set point, ensuring effective sealing and preventing unnecessary losses.</li>
<li> Compatibility: Enardo™ Enhanced Sealing PVR valves can be used in conjunction with Vapor Recovery Units (VRUs) and/or Tank Blanketing Systems for enhanced performance</li>
<li> Fully Replaceable Pallet and Seat Assemblies: Easy maintenance and repair with fully replaceable pallet and seat assemblies</li>
<li> Provide protection against positive or vacuum overpressure</li>
<li> Prevent air intake and evaporative losses of product while helping to contain odorous and potentially explosive vapours</li>
</ul>
<h1>Applications</h1>
<ul>
	<li>Wellpad tank venting for pressure control</li>
	<li>Integration with vapor recovery units (VRUs) to capture and recover emissions</li>
	<li>Emissions monitoring for compliance with environmental regulations</li>
	<li>Operational efficiency to optimize wellpad operations</li>
	<li>Safety features to minimize environmental impact</li>
</ul>
      <h1>Links</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noreferrer noopener" href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/pressure-vacuum-relief-valves/enardo-series-es-950-enhanced-sealing-high-performance-vent-to-atmosphere-pressure-vacuum-relief-val/">Enardo 950 Enhanced Sealing Pressure Vacuum Relief Valve Product Page</a></li>
		    <li><a className="text-blue-500" target='_blank' rel="noreferrer noopener" href="https://www3.emersonprocess.com/Regulators/Animation/PVRV%20850/Enardo%20PVRV%20850%20Animation.html">Enardo 850 Enhanced Sealing Pressure Vacuum Relief Valve Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default TankProtectionSolutions

